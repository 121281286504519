import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Card, Table, DatePicker, Input, Tabs, Spin } from 'antd';
import { UserOutlined, RiseOutlined, LineChartOutlined, EnvironmentOutlined, SearchOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

const { Sider, Content } = Layout;
const { RangePicker } = DatePicker;

const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
  &:hover {
    transform: scale(1.02);
  }
`;

const MetricTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
`;

const MetricValue = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: #1890ff;
  margin: 0;
`;

const SubText = styled.span`
  font-size: 12px;
  color: #8c8c8c;
`;

const ReportRegistration = ({ users }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [counts, setCounts] = useState([])
  const THAI_YEAR_OFFSET = 543;

  const [metrics, setMetrics] = useState({
    totalUsers: 0,
    growth: 0,
    topArea: 'N/A',
    monthlyTrend: [],
  });
  const [tableData, setTableData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState([]);

  const MONTH_THAI_NAMES = [
    'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน',
    'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม',
    'กันยายน'
  ];

  const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const columns = [
    {
      title: 'ปี พ.ศ.',
      dataIndex: 'year',
      key: 'year',
    },
    ...MONTH_THAI_NAMES.map((month) => ({
      title: month,
      dataIndex: month,
      key: month,
    })),
  ]


  useEffect(() => {
    if (users?.length > 0) {
      setFilteredUsers(users);
      processData(users);
    } else {
      setIsLoading(false);
    }
  }, [users]);

  const processData = (users) => {
    const countMonths = {};
    const countYears = {}
    let countUserByDateNull = 0
    let countUserByDate = 0

    let totalUsers = 0;
    let currentMonthUsers = 0;
    let lastMonthUsers = 0;
    const areaCounts = {};
    const monthlyTrend = Array(12).fill(0);

    const currentMonthIndex = new Date().getMonth();
    const lastMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;

    users.forEach((user) => {
      if (!user.created_at) return;

      const createdAt = new Date(user.created_at);
      const monthIndex = createdAt.getMonth();
      const area = user.address?.area || 'Unknown';

      totalUsers++;
      monthlyTrend[monthIndex]++;

      if (monthIndex === currentMonthIndex) currentMonthUsers++;
      if (monthIndex === lastMonthIndex) lastMonthUsers++;

      areaCounts[area] = (areaCounts[area] || 0) + 1;

      if (user.created_at === null) {
        countUserByDateNull++
      } else {
        let monthThaiIndex = createdAt.getMonth()
        let year = createdAt.getFullYear() + THAI_YEAR_OFFSET;
        if (monthThaiIndex >= 9) {
          year++
          monthThaiIndex -= 9
        }
        else {
          monthThaiIndex += 3
        }
        const month = MONTH_THAI_NAMES[monthThaiIndex];
        if (!countYears[year]) {
          countYears[year] = 1
        } else {
          countYears[year]++
        }

        if (!countMonths[year]) {
          countMonths[year] = {};
        }

        if (!countMonths[year][month]) {
          countMonths[year][month] = 1;
        } else {
          countMonths[year][month]++;
        }
        countUserByDate++
      }
    });

    const years = Object.keys(countMonths);

    const data = years.map((year) => {
      const yearData = {
        key: year,
        year,
      };

      MONTH_THAI_NAMES.forEach((month) => {
        yearData[month] = countMonths[year]?.[month] || 0
      });

      return yearData;
    })
    const dataSource = data.sort((a, b) => b.year - a.year);
    setCounts(dataSource)


    const growth = ((currentMonthUsers - lastMonthUsers) / (lastMonthUsers || 1)) * 100;
    const topAreaEntry = Object.entries(areaCounts).sort(([, a], [, b]) => b - a)[0];
    const topArea = topAreaEntry ? topAreaEntry[0] : 'N/A';

    setMetrics({ totalUsers, growth, topArea, monthlyTrend });
    setChartData([{ name: 'Registrations', data: monthlyTrend }]);
    setChartOptions({
      chart: { type: 'line', height: 300 },
      xaxis: { categories: MONTH_NAMES },
      colors: ['#1890ff'],
    });

    const tableData = users.map((user, index) => ({
      key: index,
      name: `${user.firstname || 'N/A'} ${user.lastname || ''}`.trim(),
      email: user.email || 'N/A',
      area: user.address?.area || 'N/A',
      registeredAt: new Date(user.created_at).toLocaleDateString() || 'N/A',
    }));
    setTableData(tableData);

    setIsLoading(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = users.filter((user) =>
      `${user.firstname || ''} ${user.lastname || ''}`.toLowerCase().includes(value) ||
      (user.email || '').toLowerCase().includes(value) ||
      (user.address?.area || '').toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
    processData(filtered);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);

    if (!dates) {
      setFilteredUsers(users);
      processData(users);
      return;
    }

    const [start, end] = dates;
    const filtered = users.filter((user) => {
      const createdAt = new Date(user.created_at);
      return createdAt >= start && createdAt <= end;
    });

    setFilteredUsers(filtered);
    processData(filtered);
  };

  const tableColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Area', dataIndex: 'area', key: 'area' },
    { title: 'Registered At', dataIndex: 'registeredAt', key: 'registeredAt' },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Main Content */}
      <Content style={{ padding: '20px' }}>
        {isLoading ? (
          <div style={{ textAlign: 'center', paddingTop: '50px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <Tabs>
            <Tabs.TabPane tab="สรุป" key="item-1">

              {/* Filters */}
              <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={12}>
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Search by name, email, or area"
                    value={searchText}
                    onChange={handleSearch}
                  />
                </Col>
                <Col xs={24} sm={12}>
                  <RangePicker style={{ width: '100%' }} onChange={handleDateChange} />
                </Col>
              </Row>

              {/* Metrics Cards */}
              <Row gutter={[16, 16]} style={{ marginBottom: '20px' }}>
                <Col xs={24} sm={12} lg={6}>
                  <StyledCard>
                    <UserOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                    <MetricTitle>Total Users</MetricTitle>
                    <MetricValue>{metrics.totalUsers}</MetricValue>
                    <SubText>All-time registered users</SubText>
                  </StyledCard>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <StyledCard>
                    <RiseOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
                    <MetricTitle>Growth</MetricTitle>
                    <MetricValue style={{ color: metrics.growth > 0 ? '#52c41a' : '#ff4d4f' }}>
                      {metrics.growth > 0 ? `+${metrics.growth.toFixed(2)}%` : `${metrics.growth.toFixed(2)}%`}
                    </MetricValue>
                    <SubText>Compared to last month</SubText>
                  </StyledCard>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <StyledCard>
                    <EnvironmentOutlined style={{ fontSize: '24px', color: '#faad14' }} />
                    <MetricTitle>Top Area</MetricTitle>
                    <MetricValue>{metrics.topArea}</MetricValue>
                    <SubText>Highest registrations</SubText>
                  </StyledCard>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <StyledCard>
                    <LineChartOutlined style={{ fontSize: '24px', color: '#722ed1' }} />
                    <MetricTitle>Monthly Trend</MetricTitle>
                    <MetricValue>{metrics.monthlyTrend[new Date().getMonth()]}</MetricValue>
                    <SubText>Registrations this month</SubText>
                  </StyledCard>
                </Col>
              </Row>

              {/* Charts */}
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <StyledCard>
                    <MetricTitle>Monthly Registration Trend</MetricTitle>
                    <Chart options={chartOptions} series={chartData} type="line" height={300} />
                  </StyledCard>
                </Col>
              </Row>

              {/* Table */}
              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <StyledCard>
                    <MetricTitle>User Details</MetricTitle>
                    <Table columns={tableColumns} dataSource={tableData} pagination={{ pageSize: 10 }} />
                  </StyledCard>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="ตาราง" key="item-2">
              <Row className="ml-3 mr-3 mt-2 mb-2 pt-1 pb-1">
                <Table dataSource={counts} columns={columns} scroll={{ x: 'max-content' }} pagination={false} />
              </Row>
            </Tabs.TabPane>

          </Tabs>
        )}
      </Content>
    </Layout>
  );
};

export default ReportRegistration;
