import AvailableTimes from 'react-available-times'
import React, { Component } from 'react'
import momentTimezone from 'moment-timezone'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import jwtDecode from 'jwt-decode'
import { Button, message, Row, Col, Form, Breadcrumb, Spin, Modal } from 'antd'
import { get, keys, omit, sortBy } from 'lodash'
import Layout from '../Home'
import { TABLE_TYPES } from '../../constant'
import Sidebar from '../../components/Sidebar'
import Calendar from './calendar'
import CalendarPharmacy from './calendarPharmacy'
import PatientBooking from './patientbooking'
import DoctorProfile from './doctor-profile'
import { inject, observer } from 'mobx-react'
import GoogleMapStore from '../../components/GoogleMapStore'
import firebase from 'firebase'
import moment from 'moment'
import DashboardMonitorCards from '../../components/Card/DashboardMonitorCards'
import PharmacyDashboardTable from '../../components/PharmacyDashboardTable'
import StoreCard from './StoreCard'
import TrackingTable from '../../components/trackingTable'
import './index.css'
import styled from 'styled-components'
import { TopHeaderSection, UserIcon, MenuLists, MenuItem, CardBadge } from './styles'
import CallCenterDashboardTable from '../../components/CallCenterDashboardTable'
import ReactNotifications from 'react-browser-notifications'
import { Howl } from 'howler'
import NotificationSound from '../../asset/notificationsSound/notification.mp3'
import { COMPLETED_STATUS_EMERGENCY, DASHBOARD_DATA, INPROGRESS_STATUS_EMERGENCY, PATIENT_WAITING } from './constant'
import { PRACTITIONER_ROLES } from '../../constant'
const ProfileImage = styled('div')`
  background: white;
  border-radius: 50%;
  padding: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`
@inject('AppUserIdentity')
@inject('DoctorLive')
@inject('Booking')
@inject('UserInfo')
@inject('DoctorAvailableTime')
@inject('PharmacyAvailableTime')
@inject('TrackingData')
@inject('User')
@inject('EmsForm')
@inject('PharmacyStore')
@inject('Store')
@observer
export default class DoctorSetting extends Component {
  constructor() {
    super()
    this.state = {
      fullscreen: false,
      key: 'home',
      userInfo: {},
      roles: [],
      bookings: [],
      visible: false,
      emsPositionAlert: [],
      ambulance: [],
      visibleModalCreateStore: false,
      visibleModalProfile: false,
      visibleModalTracking: false,
      visibleTimeTable: false,
      pharmacyStore: false,
      isLoading: true,
      menu: {
        homeMenu: true,
        durationMenu: false,
      },
      dashboardData: [],
      patientVideoCallingCount: 0,
      patientInLobbyCount: 0,
      dataSource: [],
      emsAvailable: 0,
      history: '',
    }
    this.showNotifications = this.showNotifications.bind(this)
  }
  howlerAudioList = {} // Interval ID obj
  intervalId = {}
  showNotifications(status, key) {
    this.soundPlay(NotificationSound, status, key)
  }

  convertTime(time) {
    return new Date(time).getDate() + '/' + (new Date(time).getMonth() + 1) + '/' + new Date(time).getFullYear()
  }
  checkTimeToNotification(time) {
    return moment().diff(moment(time), 'second') < 10
  }

  soundPlay = (src, status, key = '') => {
    if (this.n && this.n.supported() && status) this.n.show()
    if (status) {
      // * check if duplicate interval_id in same user_id return;
      if (get(this.howlerAudioList, key, '')) return
      this.howlerAudioList = {
        ...this.howlerAudioList,
        [key]: new Howl({
          src,
          html5: true,
          loop: true,
          autoplay: false,
        }),
      }
      this.intervalId = {
        ...this.intervalId,
        [key]: setInterval(() => {
          if (this.howlerAudioList[key].playing()) {
            clearInterval(this.intervalId[key])
            this.intervalId = omit(this.intervalId, [key])
          } else {
            this.howlerAudioList[key].play()
          }
        }, 2000),
      }
      return
    }
    this.cancelledNotification(key)
  }

  cancelledNotification = (key) => {
    if (get(this.intervalId, key, '')) {
      clearInterval(this.intervalId[key])
    }
    // * clear current user_id interval_id
    this.intervalId = omit(this.intervalId, [key])
    if (get(this.howlerAudioList, key, '')) {
      this.howlerAudioList[key].stop()
    }
    // * clear current user_id howler_id
    this.howlerAudioList = omit(this.howlerAudioList, [key])
  }

  async componentDidMount() {
    const { history, User } = this.props
    const user = jwtDecode(localStorage.getItem('token'))
    const resUser = await User.fetchUserInfo(user.id)
    if (user.roles.includes('pharmacyStore')) {
      this.fetchPharmacyStore()
    } else if (user.roles.includes('staff')) {
      this.fetchEmergencyData()
    }
    this.setState({
      userInfo: resUser,
      roles: user.roles,
      history: history,
    })
    this.initialDashboardData()
    this.fetchDashboardData()
    if (user.roles[0] == 'callCenter' || user.roles[0] == 'pharmacy') {
      this.interval = setInterval(() => {
        this.fetchDashboardData()
      }, 300000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  fetchDashboardData = () => {
    const user = jwtDecode(localStorage.getItem('token'))
    user.roles.includes('callCenter') && this.fetchUserInfosForCallcenter()
    user.roles.includes('pharmacy') && this.fetchTrackingDataNumber()
    if (['callCenter', 'pharmacy'].includes(user.roles[0])) {
      this.fetchBookings()
    }
    this.getFirebaseStatus()
  }

  updateLocation = async (location) => {
    const { PharmacyStore } = this.props
    const response = await PharmacyStore.updatePharmacyStore(this.state.pharmacyStore.id, location)
    if (response) {
      message.success('อัปเดต Location สำเร็จ')
    }
  }

  updateStatus = async () => {
    await DoctorLive.updateStatus(true)
  }
  callback = (key) => {
    this.setState({ key: key })
  }

  setVisible = async (visible, isSave) => {
    this.setState({
      visible: visible,
    })
    if (isSave) {
      const { DoctorAvailableTime, PharmacyAvailableTime } = this.props
      const { id } = jwtDecode(localStorage.getItem('token'))
      await DoctorAvailableTime.updateDoctorAvailableTime(id, this.state)
      message.success('อัปเดตช่วงเวลาเสร็จสิ้น')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  handleTime = (time) => {
    const setFormat = time.map((t) => {
      return [t.start, t.end]
    })
    this.setState({ time: setFormat })
  }

  selectMinute = (select) => {
    this.setState({ minute: select })
  }

  updateEmsForm = async (values) => {
    const { EmsForm } = this.props
    await EmsForm.updateEmsForm(values.id, { data: values.data, status: 'noted' })
    message.success('ทำการลงข้อมูลแบบฟอร์มสำเร็จ')
  }

  fetchPharmacyStore = async () => {
    this.setState({
      isLoading: true,
    })
    const { Store } = this.props
    const user = jwtDecode(localStorage.getItem('token'))
    const response = await Store.fetchAppUserAndPharmacyStores(user.id)
    if (response && response.length) {
      this.setState({
        pharmacyStore: response[0].pharmacyStore,
        isLoading: false,
      })
    } else {
      this.setState({
        visibleModalCreateStore: true,
        isLoading: false,
      })
    }
  }

  createPharmacyStore = async (data) => {
    const { PharmacyStore, Store } = this.props
    const { id } = jwtDecode(localStorage.getItem('token'))
    const response = await PharmacyStore.createPharmacyStore(data)
    if (response && response.id) {
      await Store.createAppUserAndPharmacyStore(id, response.id)
    }
  }

  updatePharmacyStore = async (data) => {
    const { formData, pharmacyStore } = this.state
    const { PharmacyStore } = this.props
    const { id } = jwtDecode(localStorage.getItem('token'))
    const response = await PharmacyStore.updatePharmacyStore(pharmacyStore.id, data)
    if (response.status === 200) {
      message.success('แก้ไขสำเร็จ')
      this.setState({
        pharmacyStore: response.data,
      })
    }
  }

  fetchTrackingDataNumber = async () => {
    const { TrackingData } = this.props
    const response = await TrackingData.fetchTrackingData()
    const filterResponse = sortBy(response, ['barcode'])
    const available = await TrackingData.fetchTrackingDataAvailable()
    if (response || available) {
      this.setState({
        dataSource: filterResponse,
        emsAvailable: available,
      })
    }
  }

  fetchEmergencyData = () => {
    firebase
      .database()
      .ref('ambulance')
      .on('value', (value) => {
        let data = []
        value.forEach((val) => {
          data.push({ ...val.val(), key: val.key })
        })
        this.setState({
          ambulance: data,
        })
      })
    firebase
      .database()
      .ref('emsPositionAlert')
      .on('value', (value) => {
        const data = []
        let inprogIndex = 0
        let closedIndex = 0
        value.forEach((valueStatus) => {
          const item = valueStatus.val()
          if (!item.lat || !item.lng || isNaN(item.lat) || isNaN(item.lng)) {
            console.error(`Invalid lat/lng:`, item);
            return; // Skip invalid entries
          }
          // * filter in-progress status only
          if (INPROGRESS_STATUS_EMERGENCY.includes(item.status)) {
            data.push({
              ...item,
              key: valueStatus.key,
              markerLabel: String.fromCharCode(inprogIndex + 65),
            })
            inprogIndex++
          }
          // * filter cancelled complted and pass status.
          if (COMPLETED_STATUS_EMERGENCY.includes(item.status) && this.filterDateShift(item.date)) {
            data.push({
              ...item,
              key: valueStatus.key,
              markerLabel: String.fromCharCode(closedIndex + 65),
            })
            closedIndex++
          }
        })
        const getListForCancelled = data.filter((val) => this.convertTime(val.date) === this.convertTime(new Date()) && PATIENT_WAITING !== val.status).map((item) => item.key)
        const getListNotification = data.filter((val) => this.convertTime(val.date) === this.convertTime(new Date()) && PATIENT_WAITING === val.status && this.checkTimeToNotification(val.date)).map((item) => item.key)
        const getListCancelledNotification = keys(this.howlerAudioList).filter((key) => getListForCancelled.includes(key))
        let path = this.props.history.location.pathname.split('/')[1]
        if (getListCancelledNotification.length > 0 && path !== 'login') {
          getListCancelledNotification.forEach((key) => {
            this.cancelledNotification(key)
          })
        }
        if (getListNotification.length > 0 && path !== 'login') {
          getListNotification.forEach((key) => {
            this.showNotifications(true, key)
          })
        }
        this.setState({
          emsPositionAlert: data,
        })
      })
  }

  filterDateShift = (date) => {
    const startOfShift = moment()
      .startOf('day')
      .add(8, 'hour') // * 8 am today
    const startOfShiftYesterday = moment()
      .startOf('day')
      .subtract(1, 'day')
      .add(8, 'hour') // * 8 am yesterday
    const yesterdayShift = moment().isSameOrBefore(startOfShift.subtract(1, 'second')) // * check if before 08:00 am today
    return yesterdayShift ? moment(date).isBetween(startOfShiftYesterday, startOfShift.subtract(1, 'second')) : moment(date).isSameOrAfter(startOfShift)
  }

  compareDateTime = (date) => {
    let currentDate = moment().format('DD/MM/YYYY')
    let emsDate = moment(date).format('DD/MM/YYYY')
    if (currentDate === emsDate) {
      return true
    }
    return false
  }

  getFirebaseStatus = () => {
    const { roles } = jwtDecode(localStorage.getItem('token'))
    this.setState({
      isLoading: true,
    })
    firebase
      .database()
      .ref('patientStatus')
      .on('value', (value) => {
        let data = []
        value.forEach((valueStatus) => {
          data.push({
            ...valueStatus.val(),
            key: valueStatus.key,
          })
        })

        if (['doctor', 'pharmacy'].includes(roles[0])) {
          data = data.filter((item) => {
            return item.key.includes(roles[0])
          })
        }

        const inLobbyCounted = data.filter((item) => {
          return item.bookingId && item.userId && ['inWaitingRoom', 'doctorJoin'].includes(item.status)
        }).length
        const callingCounted = data.filter((item) => {
          return item.bookingId && item.userId && item.status.includes('calling')
        }).length
        const dataWithCount = this.state.dashboardData.map((data) => {
          switch (data.key) {
            case 'patientInLobby':
              return {
                ...data,
                count: inLobbyCounted,
              }
            case 'patientVideoCalling':
              return {
                ...data,
                count: callingCounted,
              }
            default:
              return {
                ...data,
              }
          }
        })
        this.setState({
          dashboardData: dataWithCount,
        })
      })
    this.setState({
      isLoading: false,
    })
  }
  async fetchUserInfosForCallcenter() {
    const { UserInfo } = this.props
    const listUsersInfo = await UserInfo.fetchUserInfos()
    const a = 4
    const unverifyCount = listUsersInfo.filter((item) => {
      return item.verifyId === false && (!item.kycProblem || (item.kycProblem && Object.keys(item.kycProblem).length === 0))
    }).length
    const dataWithCount = this.state.dashboardData.map((data) => {
      switch (data.key) {
        case 'verifyId':
          return {
            ...data,
            count: unverifyCount,
          }

        default:
          return {
            ...data,
          }
      }
    })
    this.setState({
      dashboardData: dataWithCount,
    })
  }
  async fetchBookings() {
    this.setState({
      isLoading: true,
    })
    const { Booking } = this.props
    const response = await Booking.fetchBooking()
    this.setDashBoardData(response)
    this.setState({
      bookings: response,
      isLoading: false,
    })
  }

  initialDashboardData() {
    if (['pharmacy', 'callCenter'].includes(this.state.roles[0])) {
      this.setState({
        dashboardData: DASHBOARD_DATA[this.state.roles[0]],
      })
    }
  }
  setDashBoardData(response) {
    let dataWithCount = []
    if (this.state.roles.includes('pharmacy')) {
      dataWithCount = this.state.dashboardData.map((data) => {
        switch (data.key) {
          case 'pendingBooking':
            return {
              ...data,
              count: response.filter((item) => {
                return item.status.includes('PHARMACY_PENDING_BOOKING')
              }).length,
            }
          case 'pharmacyPendingRx':
            return {
              ...data,
              count: response.filter((item) => {
                return item.prescription && item.prescription.paymentStatus && item.prescription.status.includes('PHARMACY_PENDING_RX')
              }).length,
            }
          case 'patientPendingPayment':
            return {
              ...data,
              count: response.filter((item) => {
                return item.prescription && item.prescription.paymentStatus && item.prescription.paymentStatus.includes('PATIENT_PENDING_PAYMENT')
              }).length,
            }
          case 'patientEms':
            return {
              ...data,
              count: response.filter((item) => {
                return item.prescription && item.prescription.status && item.prescription.status.includes('WAIT_FOR_PATIENT_EMS')
              }).length,
            }
          case 'pharmacyAlert':
            return {
              ...data,
              count: response.filter((item) => {
                return item.status.includes('PHARMACY_ALERT')
              }).length,
            }
          default:
            return { ...data }
        }
      })
    } else if (this.state.roles.includes('callCenter')) {
      dataWithCount = this.state.dashboardData.map((data) => {
        switch (data.key) {
          case 'patientDraft':
            return {
              ...data,
              count: response.filter((item) => {
                return item && item.status && item.status.includes('PATIENT_DRAFT')
              }).length,
            }
          case 'bookingFollowUp':
            return {
              ...data,
              count: response.filter((item) => {
                return item && item.followUp && item.followUp === true
              }).length,
            }
          case 'ephisPending':
            return {
              ...data,
              count: response.filter((item) => {
                return item && item.status && item.status.includes('EPHIS_PENDING')
              }).length,
            }
          case 'patientPendingPayment':
            return {
              ...data,
              count: response.filter((item) => {
                return item.prescription && item.prescription.paymentStatus && item.prescription.paymentStatus.includes('PATIENT_PENDING_PAYMENT')
              }).length,
            }
          case 'patientEms':
            return {
              ...data,
              count: response.filter((item) => {
                return item.prescription && item.prescription.status && ['WAIT_FOR_PATIENT_PHARMACY', 'WAIT_FOR_PATIENT_EMS', 'WAIT_FOR_PATIENT_PHAMACYSTORE'].includes(item.prescription.status)
              }).length,
            }
          case 'patientAlert':
            return {
              ...data,
              count: response.filter((item) => {
                return item && item.status && ['DOCTOR_ALERT', 'PHARMACY_ALERT'].includes(item.status)
              }).length,
            }
          default:
            return { ...data }
        }
      })
    }

    this.setState({
      dashboardData: dataWithCount,
    })
  }
  setMenuActive = (menu) => {
    this.setState({
      menu: {
        homeMenu: menu === 'homeMenu' ? true : false,
        durationMenu: menu === 'durationMenu' ? true : false,
      },
    })
  }

  render() {
    const { DoctorLive, User } = this.props
    const { key, roles, emsPositionAlert, ambulance, visibleModalCreateStore, pharmacyStore, isLoading } = this.state

    return (
      <Layout
        {...this.props}
        render={(props) =>
          !isLoading ? (
            <div style={{ width: '100%' }}>
              <ReactNotifications
                onRef={(ref) => (this.n = ref)} // Required
                title="Emergency call !!" // Required
                body="มีผู้แจ้งเหตุฉุกเฉิน"
                icon="icon.png"
                tag="abcdef"
                timeout="2000"
              />
              {roles.includes('staff') || roles.includes('pharmacyStore') ? (
                <Row>
                  {roles.includes('pharmacyStore') ? (
                    <Col span={6} className="storeCardContainer">
                      <StoreCard store={pharmacyStore} updatePharmacyStore={this.updatePharmacyStore} />
                    </Col>
                  ) : null}
                  <Col span={roles.includes('staff') ? 24 : 18}>
                    <GoogleMapStore
                      user={this.state.userInfo}
                      roles={roles}
                      updateEmsForm={this.updateEmsForm}
                      selectedDate={this.onChangeDate}
                      emsPositionAlert={emsPositionAlert}
                      ambulance={ambulance}
                      updateLocation={this.updateLocation}
                      pharmacyStore={pharmacyStore}
                      visibleModalCreateStore={visibleModalCreateStore}
                      setVisibleModalCreateStore={(condition) => this.setState({ visibleModalCreateStore: condition })}
                      createPharmacyStore={this.createPharmacyStore}
                      history={props.history}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="w-100">
                  <Row className="p-3 w-100">
                    {['pharmacy', 'callCenter'].includes(roles[0]) && (
                      <>
                        <Col span={24} className="mt-2">
                          <h1 className="h3 font-weight-bold">{roles.includes('pharmacy') ? 'หน่วยจ่ายยา' : roles.includes('callCenter') ? 'Call Center' : ''}</h1>
                        </Col>
                        <Col span={24}>
                          <MenuLists>
                            <MenuItem
                              active={this.state.menu.homeMenu}
                              onClick={() => {
                                this.setMenuActive('homeMenu')
                                this.setState({ visibleTimeTable: false })
                              }}
                            >
                              หน้าหลัก
                            </MenuItem>
                            {roles.includes('pharmacy') && (
                              <>
                                <MenuItem>/</MenuItem>
                                <MenuItem
                                  active={this.state.menu.durationMenu}
                                  onClick={() => {
                                    this.setMenuActive('durationMenu')
                                    this.setState({ visibleTimeTable: true })
                                  }}
                                >
                                  ช่วงเวลา
                                </MenuItem>
                              </>
                            )}
                          </MenuLists>
                        </Col>
                        <Col span={24} className="my-3">
                          {this.state.menu.homeMenu ? (
                            <DashboardMonitorCards history={props.history} data={this.state.dashboardData} />
                          ) : (
                            this.state.menu.durationMenu && (
                              <div className="container mt-3">
                                <CalendarPharmacy handleTime={this.handleTime} />
                              </div>
                            )
                          )}
                        </Col>
                      </>
                    )}
                    {PRACTITIONER_ROLES.includes(roles[0]) ? (
                      <Col span={24}>
                        <Tabs defaultActiveKey="home" activeKey={key} onSelect={this.callback}>
                          <Tab eventKey="home" title="หน้าหลัก">
                            <DoctorProfile props={props} />
                            <PatientBooking
                              props={props}
                              doctorLive={DoctorLive}
                              visibleModalProfile={this.state.visibleModalProfile}
                              setVisibleModalProfile={(status) => {
                                this.setState({ visibleModalProfile: status })
                              }}
                              visibleTimeTable={this.state.visibleTimeTable}
                            />
                          </Tab>

                          {PRACTITIONER_ROLES.includes(roles[0]) && (
                            <Tab eventKey="timeslot" title="ช่วงเวลา">
                              {key === 'timeslot' ? (
                                <div className="container mt-3">
                                  <Calendar handleTime={this.handleTime} selectMinute={this.selectMinute} updateInitialPharmacy={this.updateInitialPharmacy} />
                                  <div className="text-right mt-4">
                                    <Button style={{ background: '#27cf00', border: '1px solid #174a0b', color: 'black' }} onClick={() => this.setVisible(false, true)}>
                                      บันทึกข้อมูลช่วงเวลา
                                    </Button>
                                  </div>
                                </div>
                              ) : null}
                            </Tab>
                          )}
                        </Tabs>
                      </Col>
                    ) : roles.includes('pharmacy') ? (
                      !this.state.visibleTimeTable && (
                        <Col span={24}>
                          <PharmacyDashboardTable
                            dataSource={this.state.bookings}
                            setVisibleModalTracking={(status) => {
                              this.setState({ visibleModalTracking: status })
                            }}
                            emsAvailable={this.state.emsAvailable}
                            history={this.state.history}
                          ></PharmacyDashboardTable>
                          <PatientBooking
                            props={props}
                            doctorLive={DoctorLive}
                            visibleModalProfile={this.state.visibleModalProfile}
                            setVisibleModalProfile={(status) => {
                              this.setState({ visibleModalProfile: status })
                            }}
                            visibleTimeTable={this.state.visibleTimeTable}
                          />
                        </Col>
                      )
                    ) : (
                      roles.includes('callCenter') && (
                        <Col span={24}>
                          <CallCenterDashboardTable dataSource={this.state.bookings} history={this.state.history}></CallCenterDashboardTable>
                          <PatientBooking
                            props={props}
                            doctorLive={DoctorLive}
                            visibleModalProfile={this.state.visibleModalProfile}
                            setVisibleModalProfile={(status) => {
                              this.setState({ visibleModalProfile: status })
                            }}
                            visibleTimeTable={this.state.visibleTimeTable}
                          />
                        </Col>
                      )
                    )}
                  </Row>
                </Row>
              )}
              <Modal visible={this.state.visibleModalTracking} onOk={() => this.setState({ visibleModalTracking: false })} onCancel={() => this.setState({ visibleModalTracking: false })} title="Tracking Number" footer={null} width={1200}>
                {roles.includes('pharmacy') && (
                  <Spin spinning={isLoading}>
                    <TrackingTable state={this.state} fetchTrackingDataNumber={this.fetchTrackingDataNumber} />
                  </Spin>
                )}
              </Modal>
            </div>
          ) : (
            <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
              <Spin size="large" />
            </div>
          )
        }
      />
    )
  }
}
